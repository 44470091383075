<template>
  <div class="fill-height pb-12">
    <div v-if="loading" class="h-100 d-flex align-center justify-center">
      <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
    </div>

    <template v-if="!loading">
      <v-navigation-drawer
        v-if="$route.name !== 'SignIn' && currentIntel"
        app
        dark
        width="225"
        class="grey darken-4 ss-advisor-dash__nav-drawer"
      >
        <v-sheet class="pa-6 grey darken-4 ss-advisor-dash__logo">
          <img src="@/assets/risk-score-logo-full-blue-white-darkbg.svg"
        /></v-sheet>

        <v-list dense nav>
          <template v-for="item in currentIntel.nav.navItems">
            <v-list-item
              v-if="!item.items"
              :disabled="item.disabled"
              :exact="item.exact"
              :key="item.title"
              :ripple="false"
              :to="item.to"
              :tag="item.tag"
              class="mb-4"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-if="item.items"
              :key="item.title"
              v-model="item.active"
              no-action
              class="mb-4"
            >
              <template v-slot:activator>
                <v-list-item-icon class="mr-4">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-if="item.items">
                <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
                  :exact="child.exact"
                  :ripple="false"
                  :to="child.to"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </v-list>
        <v-divider class="mb-4"></v-divider>
        <v-list dense nav>
          <div v-for="item in currentIntel.nav.navItems2" :key="item.title">
            <v-list-item
              v-if="item.clickAction"
              :disabled="item.disabled"
              :exact="item.exact"
              :ripple="false"
              :tag="item.tag"
              :class="item.classes"
              @click.prevent="handleItemClickAction(item.clickAction)"
              class="mb-4"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else
              :disabled="item.disabled"
              :exact="item.exact"
              :ripple="false"
              :to="item.to"
              class="mb-4"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>

        <div class="pa-4" style="position: absolute; bottom: 0; width: 100%">
          <v-btn block small text color="grey lighten-1" class="d-flex align-center mb-4">
            <v-icon class="mr-2" small> mdi-account </v-icon>
            {{ intelUser }}
          </v-btn>
          <v-divider class="mb-4"></v-divider>
          <v-btn small text block color="grey lighten-1" @click="handleSignOut">
            <v-icon class="mr-2" small>mdi-logout</v-icon> Sign out</v-btn
          >
        </div>
      </v-navigation-drawer>

      <v-main class="ss-advisor-dash__main">
        <v-toolbar
          v-if="$route.name !== 'SignIn' && currentIntel"
          flat
          color="rgba(240, 242, 245, 0.8)"
          class=""
          style="position: sticky; top: 0; z-index: 1; backdrop-filter: blur(4px)"
        >
          <v-row>
            <v-col class="d-flex col-sm-6 col-12">
              <h3 v-if="userLevel !== 'advisor'">{{ currentIntel.title }}</h3>
              <h3 v-else>Welcome, {{ currentIntel.firstName }}!</h3>
              <!-- <v-breadcrumbs :items="breadCrumbs" class="pl-2">
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item
                    v-if="item.text === 'Dashboard'"
                    :href="item.href"
                    :disabled="item.disabled"
                  >
                    <v-icon class="mr-2">mdi-home</v-icon> Dashboard
                  </v-breadcrumbs-item>
                  <v-breadcrumbs-item
                    v-else
                    :href="item.href"
                    :disabled="item.disabled"
                  >
                    {{ item.text }}
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs> -->
            </v-col>
            <v-col class="d-flex align-center justify-end col-sm-6 col-12 text-sm-right">
              <v-responsive max-width="300" class="mr-4">
                <v-text-field
                  dense
                  flat
                  hide-details
                  rounded-sm
                  outlined
                  clearable
                  label="Search"
                ></v-text-field>
              </v-responsive>

              <v-btn icon x-small class="mr-4">
                <v-icon>mdi-cog</v-icon>
              </v-btn>

              <v-btn icon x-small class="mr-4">
                <v-icon>mdi-bell</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <div class="container px-6 pb-6 container--fluid">
          <v-fade-transition mode="out-in">
            <router-view :key="$router.path"></router-view>
          </v-fade-transition>
        </div>
      </v-main>
      <v-footer
        v-if="$route.name !== 'SignIn'"
        color="transparent"
        height="72"
        inset
        absolute
        style="padding-left: 250px"
      >
        <p class="mb-0">
          <small
            >© {{ currentYear }} The Secure Companies, Inc. All rights reserved.</small
          >
        </p>
      </v-footer>

      <v-dialog
        v-model="showProspectInviteDialog"
        max-width="800"
        width="75%"
        @input="handleResetDialogOnClose"
      >
        <v-card>
          <v-card-title class="d-flex mb-4">
            <h3>Invite Prospect</h3>
            <v-btn
              icon
              @click="
                handleResetDialogOnClose(false);
                showProspectInviteDialog = false;
              "
              class="ml-auto"
            >
              <v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <p>
              Invite a prospect to take the Risk Score Survey. The prospect will receive
              an email with a link to take the Risk Score Survey in the context of your
              advisor account.
            </p>
            <ProspectCreateForm
              :advisorId="advisorId"
              :origin="origin"
              :resetInviteForm="resetInviteForm"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { mapState, mapActions } from "vuex";
import ProspectCreateForm from "@/components/ProspectCreateForm.vue";
import { getLevelConfig } from "@/components/intel/services/getLevelConfig";
import { getAdvisor, getIntelGroup, getIntelOrg } from "@/graphql/queries";

export default {
  components: {
    ProspectCreateForm,
  },
  data() {
    return {
      advisorId: null,
      origin: "invite",
      resetInviteForm: false,
      showProspectInviteDialog: false,
      loading: false,
      userLevel: null,
      nav: null,
    };
  },
  computed: {
    ...mapState(["currentIntel", "intelUser"]),
    currentYear() {
      return new Date().getFullYear();
    },
    breadCrumbs: function () {
      // console.log("this.$route.path", this.$route.path);

      let pathArray = this.$route.path.split("/");
      const startPath = "/" + pathArray[3];
      // console.log("startPath", startPath);
      let breadCrumbs = [];

      if (pathArray[3]) {
        pathArray = startPath.split("/");

        pathArray.forEach((path) => {
          // console.log("path", path);
          if (path === "") {
            breadCrumbs.push({
              path: "",
              to: "",
              text: "Dashboard",
              disabled: false,
              exact: true,
              icon: "mdi-view-dashboard",
            });
          } else {
            breadCrumbs.push({
              path: path,
              to: path,
              text: path.charAt(0).toUpperCase() + path.slice(1),
              disabled: false,
              exact: true,
            });
          }
        });
        // pathArray.shift();
        // console.log("patharary", pathArray);
        // breadCrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        //   console.log("breadcrumbArray", breadcrumbArray);
        //   breadcrumbArray.push({
        //     path: path,
        //     to: breadcrumbArray[idx - 1]
        //       ? "/" + breadcrumbArray[idx - 1].path + "/" + path
        //       : "/" + path,
        //     text: this.$route.matched[idx].meta.breadCrumb || path,
        //     disabled: false,
        //   });

        //   return breadcrumbArray;
        // }, []);
      } else {
        breadCrumbs = [
          {
            path: "",
            to: "",
            text: "Dashboard",
            disabled: true,
          },
        ];
      }

      return breadCrumbs;
    },
  },
  created() {
    this.loading = true;
    if (localStorage.getItem("intelUser")) {
      this.setIntelUser(localStorage.getItem("intelUser"));
    }

    if (this.currentIntel && this.currentIntel.nav) {
      this.loading = false;
      return;
    }

    const userLevel = localStorage.getItem("intelLevel");

    if (!userLevel) {
      if (this.$route.path !== "/intel-signin") {
        this.$router.push("intel-signin");
      }
      this.loading = false;
      return;
    } else {
      const level = getLevelConfig(userLevel);
      console.log("LLLLLL", level);
      const advisorId = this.$route.query.advisor;
      if (advisorId) {
        level.id = advisorId;
        localStorage.setItem("intelAdvisorId", advisorId);
      } else if (localStorage.getItem("intelAdvisorId")) {
        level.id = localStorage.getItem("intelAdvisorId");
      }

      this.advisorId = level.id;

      console.log("llll2", level);
      this.userLevel = userLevel;
      this.setup(userLevel, level);
    }
  },
  mounted() {
    console.log("REFRESHING");
  },
  methods: {
    ...mapActions(["setCurrentIntel", "setIntelUser", "resetIntelState"]),
    async setup(userLevel, level) {
      console.log("Intel SETUP ", userLevel, level);
      let query = getIntelOrg;
      let queryAsString = "getIntelOrg";

      if (userLevel === "group") {
        query = getIntelGroup;
        queryAsString = "getIntelGroup";
      }

      if (userLevel === "advisor") {
        query = getAdvisor;
        queryAsString = "getAdvisor";
      }

      try {
        const response = await API.graphql(graphqlOperation(query, { id: level.id }));

        const data = response.data[queryAsString];

        console.log("data", data);

        if (data) {
          data.nav = level.nav;
          this.setCurrentIntel(data);
        }
      } catch (error) {
        console.log("Error getting org", error);
      } finally {
        this.loading = false;
      }
    },
    handleSignOut() {
      console.log("here");
      localStorage.removeItem("intelLevel");
      localStorage.removeItem("intelActiveProducts");
      localStorage.removeItem("intelAdvisorId");
      this.resetIntelState();
      this.$router.push("/intel-signin");
    },
    handleItemClickAction(action) {
      if (action === "showProspectInviteDialog") {
        this.showProspectInviteDialog = !this.showProspectInviteDialog;
      }
    },
    handleResetDialogOnClose(e) {
      if (!e) {
        this.resetInviteForm = true;
      }
    },
  },
};
</script>

<style lang="scss">
.ss-intel {
  &.theme--light.v-application {
    background-color: #f0f2f5;
  }

  &__main {
    @media screen and (min-width: 1264px) {
      /* padding-left: 274px !important; */
    }
  }

  &__nav-drawer {
    max-width: 15.625rem !important;
    /* height: calc(100% - 2rem) !important; */
  }

  .theme--dark {
    .v-list-item--active,
    .v-list-item--active:hover {
      color: var(--v-primary-base);

      .v-list-item__title {
        font-weight: bold;
      }

      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
